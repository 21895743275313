
import { Vue, Component } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Translations from '@/store/modules/translations'

const translations = getModule(Translations)

// Keys for accessing translations json
const REFRESH_KEY = 'recordingsRefreshText'
const STILL_THERE_KEY = 'recordingsAreYouStillThereText'
const QUIET_KEY = 'recordingsGoneQuietText'
const PROMPT_KEY = 'recordingsRefreshPromptText'
/**
 * This component is called when the user's audio is quiet for over one minute.
 * TODO: update this component once audio functionality is written.
 * TODO: implement refresh on button click.
 */
@Component
export default class AudioPrompt extends Vue {
  stillHereText = ''
  goneQuietText = ''
  refreshText = ''
  refreshPromptText = ''

  mounted () {
    this.refreshText = translations.getTranslations(REFRESH_KEY)
    this.refreshPromptText = translations.getTranslations(PROMPT_KEY)
    this.goneQuietText = translations.getTranslations(QUIET_KEY)
    this.stillHereText = translations.getTranslations(STILL_THERE_KEY)
  }
}
