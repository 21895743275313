
import AxiosConfig from './axiosConfig'
// import * as FormData from 'form-data'
// import { createReadStream } from 'fs'
import Configuration from '@/store/modules/configuration'
import { getModule } from 'vuex-module-decorators'

import { SERVICES, ENDPOINTS, COLOR_VARIANT, UI_TEXT_TOAST_ERROR_UNAUTHORIZED, STATUS_CODE_UNAUTHORIZED } from '../constants'

import ApiException from '@/exceptions/ApiException'
import axios from 'axios'

export default class FileUploadApi {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static async uploadFile (params: Record<string, any>): Promise<Record<string, string>> {
    const configuration = getModule(Configuration)

    // const response = await axios.get(params.filePath)
    const fileResponse = await axios({
      method: 'get',
      url: params.fileUrl,
      responseType: 'blob'
    })

    const file = fileResponse.data
    // This is now working-ish, we are sending something but it's outputted as a random string of characters rather than the image

    const url = `${configuration.endpoint(SERVICES.AUDIO, ENDPOINTS.RECORDING_BUCKET)}/original/${params.fileName}`
    try {
      const response = await AxiosConfig.getInstance().put(SERVICES.AUDIO, url, file)

      return response.data
    } catch (error) {
      console.error(error)

      const errorAsDict: Record<string, any> = error as Record<string, any>

      if (errorAsDict.response && errorAsDict.response.status === STATUS_CODE_UNAUTHORIZED) {
        throw new ApiException(null, COLOR_VARIANT.WARNING, UI_TEXT_TOAST_ERROR_UNAUTHORIZED)
      } else {
        throw Error
      }
    }
  }
}
