
import Recording from '@/store/modules/recording'
import RecordingPart from '@/store/modules/recordingPart'
import User from '@/store/modules/user'
import Transcripts from '@/store/modules/transcripts'
import Translations from '@/store/modules/translations'

import Store from '@/store/index'

import RecordHello from '@/components/recordings/RecordHello.vue'
import PlayCurrentRecording from '@/components/recordings/PlayCurrentRecording.vue'
import CountdownHelper from '@/components/recordings/CountdownHelper.vue'

import api from '@/api/main'

import AudioUtils from '@/utils/audio'

import fileApi from '@/api/fileUpload'

import EventManager from '@/assets/libs/EventManager'

import Component from 'vue-class-component'
import { Vue, Watch } from 'vue-property-decorator'

import { getModule } from 'vuex-module-decorators'

import { audioRecorder } from '@/assets/libs/AudioEncoderDecoder/AudioEncoderDecoder'

import {
  RECORDING_STATE,
  COLOR_VARIANT,
  EVENTS,
  AUDIO_DOM_ID,
  AUDIO_DOM_PLAYBACK_ID
} from '@/constants'
import ApiException from '@/exceptions/ApiException'

const recording = getModule(Recording, Store)
const recordingPart = getModule(RecordingPart, Store)
const user = getModule(User, Store)
const transcripts = getModule(Transcripts)
const translations = getModule(Translations) // Accesssor for translations state.

const SAY_WAKEWORD_KEY = 'recordingsSayWakewordText'
const LISTEN_KEY = 'recordingsHaveAListenText'
const SATISFIED_KEY = 'recordingsSatisfiedText'

const UI_TEXT_TOAST_ERROR_TITLE = 'toastErrorErrorTitle'
const UI_TEXT_TOAST_RECORDINGS_TITLE = 'toastErrorRecordingsTitle'
const UI_TEXT_TOAST_ERROR_MICROPHONE_ACCESS = 'toastErrorMicrophoneAccess'
const UI_TEXT_TOAST_ERROR_CREATE_PART = 'toastErrorRecordPartCreate'

@Component({
  components: {
    RecordHello,
    PlayCurrentRecording,
    CountdownHelper
  }
})
export default class RecordWakewords extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instanceAudioRecorder: any = audioRecorder
  mediaStream: MediaStream | null = null
  mediaRecorder: MediaRecorder | null = null
  isSendingRecordingPart = false
  timeOutNoUserAction: NodeJS.Timeout | null = null

  sayWakewordText = translations.getTranslations(SAY_WAKEWORD_KEY)
  haveListenText = translations.getTranslations(LISTEN_KEY)
  satisfiedText = translations.getTranslations(SATISFIED_KEY)

  /**
   * This component controls the recording audio process.
   * The state is what drives what is rendered
    IDLE: -1,
    BEGIN: 0,
    COUNTING_DOWN: 1,
    RECORDING: 2,
    PLAYBACK: 3,
    TIME_OUT: 4,
    ERROR: 5
   * Within each of those components, state is updated as and when needed to move to the next stage in the process.
   */
  get status () {
    return recording.getStatus
  }

  get transcript () {
    return recordingPart.getTranscript
  }

  @Watch('status')
  // eslint-disable-next-line
  onStatusChanged (val: number, oldVal: number) {
    if (val === RECORDING_STATE.COUNTING_DOWN) {
      // Reset the array of bytes to be empty
      recordingPart.resetRecordingData()

      // Start the audio recorder again
      this.startAudioRecorder()
      // this.startMediaRecorder()
    }
  }

  mounted () {
    if (this.status === RECORDING_STATE.COUNTING_DOWN) {
      this.startAudioRecorder()
      // this.startMediaRecorder()
    }
  }

  // Called when the timer for a recording is over and the record is therefore done
  handleRecordPartFinished () {
    recordingPart.setFileName(
      {
        recordingId: recording.getRecordingId,
        userId: user.getUserId
      })

    EventManager.getInstance().removeListener(EVENTS.STREAM_AVAILABLE, this.handleMicrophoneStreamAvailable)

    if (this.mediaRecorder !== null) {
      this.mediaRecorder.stop()
    }

    this.$emit('on-recording-part-finished')
  }

  // Called when the recorded audio data is available for use
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRecordedAudioDataAvailable (event: BlobEvent) {
    // The mediaRecorder, using the microphone stream, automatically calls this callback either when we stopped the mediaRecorder of the
    // microphone, whose media stream is linked to the mediaRecorder, gets closed
    // So we need to check the status of the recording to make sure it gets called when appropriate, at the end of a real recording
    if (this.status === RECORDING_STATE.PLAYBACK) {
      // Add the audio html element in order to let the user playback the audio
      this.addAudioDomForPlayback(event.data)

      this.stopRecording()
    }
  }

  async addAudioDomForPlayback (data: Blob) {
    recordingPart.setRecordingBlob(data)

    const divToAttachTo: HTMLDivElement = document.getElementById(AUDIO_DOM_ID) as HTMLDivElement
    if (divToAttachTo !== null) {
      // Now we will check that we're not coming here after the user click on the pop up saying they want
      // to carry on with their sessions even though they'v ebeen quiet for 30s
      let audioHtmlElement: HTMLAudioElement = document.getElementById(AUDIO_DOM_PLAYBACK_ID) as HTMLAudioElement
      if (audioHtmlElement === null) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        audioHtmlElement = document.createElement('audio') as HTMLAudioElement
        audioHtmlElement.id = AUDIO_DOM_PLAYBACK_ID
        audioHtmlElement.controls = true
        audioHtmlElement.src = URL.createObjectURL(data)

        divToAttachTo.appendChild(audioHtmlElement)
      }
    }
  }

  async handleRedoRecordingPartClicked () {
    try {
      const isMicrophoneAvailable = await AudioUtils.checkMicrophonePermissionAvailable()

      if (isMicrophoneAvailable) {
        recording.updateStatus(RECORDING_STATE.COUNTING_DOWN)
        this.$emit('on-redo-recording-part')
      } else {
        this.$bvToast.toast(translations.getTranslations(UI_TEXT_TOAST_ERROR_MICROPHONE_ACCESS), {
          title: translations.getTranslations(UI_TEXT_TOAST_ERROR_TITLE),
          variant: COLOR_VARIANT.DANGER,
          solid: true
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  async handleNextRecordingPartClicked () {
    try {
      this.isSendingRecordingPart = true

      const isMicrophoneAvailable = await AudioUtils.checkMicrophonePermissionAvailable()

      if (isMicrophoneAvailable) {
        const params: Record<string, string|number|boolean> = this.getRecordingPartApiParams()

        await api.createRecordMetadata(params)

        await this.uploadRecordingToS3()

        // Increment the part id, so we know where it is
        recordingPart.incrementIndex()

        const transcription: Record<string, string> = transcripts.getTranscription(recordingPart.getIndex - 1)

        recordingPart.setType(transcription.type)
        recordingPart.setTranscript(transcription.transcript)

        recording.updateStatus(RECORDING_STATE.COUNTING_DOWN)

        // Send the Recording part and wait for it, before moving on
        // this.startAudioRecorder()
        // this.startMediaRecorder()
      }
    } catch (apiError) {
      let toastMessageKey: string = UI_TEXT_TOAST_ERROR_CREATE_PART
      let toastColorVariant: string = COLOR_VARIANT.DANGER

      if (apiError instanceof ApiException) {
        toastMessageKey = apiError.toastMessage
        toastColorVariant = apiError.toastColor
      }

      // Show a toast it couldn't be sent, and the user needs to try to send again
      this.$bvToast.toast(translations.getTranslations(toastMessageKey), {
        title: translations.getTranslations(UI_TEXT_TOAST_RECORDINGS_TITLE),
        variant: toastColorVariant,
        solid: true
      })
    } finally {
      this.isSendingRecordingPart = false
    }
  }

  async handleFinishedAllRecordingPartClicked () {
    try {
      this.isSendingRecordingPart = true

      await this.uploadRecordingToS3()

      const params: Record<string, string|number|boolean> = this.getRecordingPartApiParams()

      await api.createRecordMetadata(params)

      this.$router.push('/done')
    } catch (apiError) {
      let toastMessageKey: string = UI_TEXT_TOAST_ERROR_CREATE_PART
      let toastColorVariant: string = COLOR_VARIANT.DANGER

      if (apiError instanceof ApiException) {
        toastMessageKey = apiError.toastMessage
        toastColorVariant = apiError.toastColor
      }

      // Show a toast it couldn't be sent, and the user needs to try to send again
      this.$bvToast.toast(translations.getTranslations(toastMessageKey), {
        title: translations.getTranslations(UI_TEXT_TOAST_RECORDINGS_TITLE),
        variant: toastColorVariant,
        solid: true
      })
    } finally {
      this.isSendingRecordingPart = false
    }
  }

  // Called by the AudioEncoderDecover class when the microphone gets opened and start having a stream
  handleMicrophoneStreamAvailable (stream: MediaStream) {
    this.mediaStream = stream

    this.mediaRecorder = new MediaRecorder(this.mediaStream)
    this.mediaRecorder.ondataavailable = this.handleRecordedAudioDataAvailable

    // First time this is called is when we mount the component and gets the microhpone audio data
    // If the user is inactivte, and click on continue on the pop-up pointing it out
    // The program will automatically try to re-capture the microphone stream to send it to the backend
    // So only if we're in the first time this component is mounted and therefore in counting down mode that
    // we need to start the media Recorder
    // If could have been handled better with UI status vs Model Status
    if (recording.getStatus === RECORDING_STATE.COUNTING_DOWN) {
      // this.startAudioRecorder()
      this.startMediaRecorder()
    }
  }

  getRecordingPartApiParams (): Record<string, string|number|boolean> {
    return {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user.getUserId,
      // eslint-disable-next-line @typescript-eslint/camelcase
      recording_id: recording.getRecordingId,
      // eslint-disable-next-line @typescript-eslint/camelcase
      part_id: recordingPart.getIndex,
      // eslint-disable-next-line @typescript-eslint/camelcase
      file_name: recordingPart.getFileName,
      type: recordingPart.getType,
      transcript: recordingPart.getTranscript
    }
  }

  startAudioRecorder () {
    EventManager.getInstance().addListener(EVENTS.STREAM_AVAILABLE, this.handleMicrophoneStreamAvailable)

    const transcription: Record<string, string> = transcripts.getTranscription(recordingPart.getIndex - 1)

    recordingPart.setType(transcription.type)
    recordingPart.setTranscript(transcription.transcript)

    this.instanceAudioRecorder = audioRecorder
    this.instanceAudioRecorder.start()
  }

  startMediaRecorder () {
    if (this.mediaRecorder !== null && this.mediaRecorder.state !== 'recording') {
      this.mediaRecorder.start()
    }

    // We check the state to make sure we aren't just rewiring after the user
    // went quiet for more than 30s, and we're just replugging every thing
    if (recording.getStatus !== RECORDING_STATE.PLAYBACK) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const divToDetachFrom: HTMLDivElement | null = document.getElementById(AUDIO_DOM_ID) as HTMLDivElement
      const audioPlaybackHtmlElement: HTMLAudioElement | null = document.getElementById(AUDIO_DOM_PLAYBACK_ID) as HTMLAudioElement

      if (divToDetachFrom !== null && audioPlaybackHtmlElement !== null) {
        audioPlaybackHtmlElement.onplay = null
        divToDetachFrom.removeChild(audioPlaybackHtmlElement)
      }
    }
  }

  stopRecording () {
    this.mediaStream = null

    this.instanceAudioRecorder.stop()
    this.instanceAudioRecorder = null

    if (this.mediaRecorder) {
      this.mediaRecorder.ondataavailable = null
      this.mediaRecorder = null
    }
  }

  async uploadRecordingToS3 () {
    const file = recordingPart.getRecordingBlob
    const fileName = recordingPart.getFileName
    const fileUrl = URL.createObjectURL(file)

    try {
      await fileApi.uploadFile({
        fileName: `${fileName}.wav`,
        fileUrl: fileUrl
      })
    } catch (error) {
      console.log(`Error uploading audio file: ${error}`)
    }
  }
}

