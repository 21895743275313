
import { mixins } from 'vue-class-component'
import { Vue, Component } from 'vue-property-decorator'

import { getModule } from 'vuex-module-decorators'

import AudioUtils from '@/utils/audio'

import Recording from '@/store/modules/recording'
import Translations from '@/store/modules/translations'

import { RECORDING_STATE } from '@/constants'

import Store from '@/store/index'

const translations = getModule(Translations) // Accesssor for translations state.
const recording = getModule(Recording, Store) // Accessor for recordings state.

const BEGIN_KEY = 'recordingsBeginText'
const CONTINUE_SESSION_KEY = 'recordingsContinueSessionText'

const UI_TEXT_TOAST_ERROR_TITLE = 'toastErrorErrorTitle'
const UI_TEXT_TOAST_ERROR_MICROPHONE_ACCESS = 'toastErrorMicrophoneAccess'

// Define the props by using Vue's canonical way.
const InitiateRecordingProps = Vue.extend({
  props: {
    isResumedSession: Boolean
  }
})

@Component
export default class InitiateRecording extends mixins(InitiateRecordingProps, Vue) {
  /**
   * This component is rendered from: RecordingsPage.vue (the main view)
   * The purpose is to provide the users with a button 'Begin',
   * which triggers the beginning of the recording of the wakewords,
   * i.e this component sets beginRecording = true (recording.ts)
   */
  beginText = translations.getTranslations(BEGIN_KEY)
  continueText = translations.getTranslations(CONTINUE_SESSION_KEY)

  // This method handles the click of the begin button.
  // Update beginRecordings state which will trigger a change of the dom.
  // Instead of this component begin rendered, <RecordWakewords /> will be recorded.
  // See the main page for more details (RecordingsPage.vue)
  async handleClick () {
    try {
      const isMicrophoneAvailable = await AudioUtils.checkMicrophonePermissionAvailable()

      if (isMicrophoneAvailable) {
        recording.updateStatus(RECORDING_STATE.COUNTING_DOWN)
      } else {
        this.$bvToast.toast(translations.getTranslations(UI_TEXT_TOAST_ERROR_MICROPHONE_ACCESS), {
          title: translations.getTranslations(UI_TEXT_TOAST_ERROR_TITLE),
          variant: 'danger',
          solid: true
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}
